let height;
const sendPostMessage = () => {
  if (height !== document.querySelector('body').offsetHeight) {
    height = document.querySelector('body').offsetHeight;
    console.log(height);
    window.parent.postMessage({
      frameHeight: height
    }, '*');
  }
}


function hitEnter(){
    
    const input = document.getElementById("id-61ffb2e9");
    const button = document.getElementById("id-46aa7134");
input.addEventListener("keydown", function(event) {
    

    
    
  if (event.keyCode === 13) {
        button.click();
        return;

  }
  
  if (input.value.length > 4) {
    input.value = input.value.slice(0, -1);
    }
  
  
  
});

}



window.Client = {
	init: function() {
		// Do something on initialization
		sendPostMessage();
		var checkbox = document.getElementById("checkbox-0014d968");
		checkbox.checked = true;
		
		hitEnter();
		
		
		
	},

	onRender: function(screenId) {
		// Do something when the flow renders
		sendPostMessage();
	}
}
